










import Component from 'vue-class-component'
import Vue from "vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";

@Component({
  components: {SygniRoundedButton},
})
export default class FundsEmptyTable extends Vue {
  goToAddNewFund(): void {
    this.$router.push({name: 'add-new-fund'});
  }
}

