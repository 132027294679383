var render = function () {
  var _vm$funds, _vm$funds2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dashboard"
  }, [_c('div', {
    staticClass: "dashboard__left"
  }, [_c('FundManagerCard', {
    attrs: {
      "fundManagerData": _vm.fundManagerData
    }
  }), ((_vm$funds = _vm.funds) === null || _vm$funds === void 0 ? void 0 : _vm$funds.length) > 0 ? _c('sygni-rounded-button', {
    staticClass: "filled gn-primary add-new-report",
    attrs: {
      "selectable": false,
      "plus-icon": true
    },
    on: {
      "click": function click($event) {
        return _vm.goToAddNewFund();
      }
    }
  }, [_c('span', {
    staticClass: "show-lg"
  }, [_vm._v("Add new fund")]), _c('span', {
    staticClass: "hide-lg"
  }, [_vm._v("Add new")])]) : _vm._e(), _c('sygni-container-title', {
    staticClass: "fund-title"
  }, [_vm._v(" Funds under management ")]), ((_vm$funds2 = _vm.funds) === null || _vm$funds2 === void 0 ? void 0 : _vm$funds2.length) > 0 ? _c('funds-table', {
    attrs: {
      "items": _vm.funds,
      "showPagination": false
    }
  }) : _c('funds-empty-table')], 1), _c('div', {
    staticClass: "dashboard__right"
  }, [_c('sygni-container-title', [_vm._v(" Management Team ")]), _c('management-team', {
    attrs: {
      "team": _vm.managementTeam
    }
  }), _c('sygni-container-title', [_vm._v(" Information ")]), _c('fund-information-card', {
    attrs: {
      "legalEntity": _vm.fundManagerData
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }