






















import Vue from 'vue'
import Component from 'vue-class-component'
import SygniCard from "@/components/layout/SygniCard.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import FundsTable from "@/modules/genprox/modules/AIFM/modules/dashboard/components/FundsTable.vue";
import {
  FundTableActions,
  FundTableItem
} from "@/modules/genprox/modules/AIFM/modules/dashboard/store/types";
import UserPresentation from "@/components/UserPresentation.vue";
import FundManagerCard from "@/modules/genprox/modules/AIFM/components/FundManagerCard.vue";
import ManagementTeam from "@/modules/genprox/modules/AIFM/components/ManagementTeamCard.vue";
import FundInformationCard from "@/modules/genprox/modules/AIFM/components/FundInformationCard.vue";
import {mapState} from "vuex";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import FundsEmptyTable from "@/modules/genprox/modules/AIFM/modules/dashboard/components/FundsEmptyTable.vue";
import {FundManager, MTMember} from "@/modules/genprox/models/User";

@Component({
  components: {
    FundsEmptyTable,
    SygniRoundedButton,
    FundInformationCard,
    ManagementTeam, FundManagerCard, UserPresentation, FundsTable, SygniContainerTitle, SygniCard},
  computed: {...mapState('genprox', {
      managementTeam: (state: any) => state.managementTeam
    }), ...mapState('regReporting', {
      fundManagerData: (state: any) => state.fundData
    })}
})
export default class Dashboard extends Vue {
  fundManagerData!: FundManager;

  funds: FundTableItem[] = []

  managementTeam!: MTMember[];

  get nameSymbol(): String {
    return this.fundManagerData.name?.slice(0,1);
  }

  async mounted(): Promise<void> {
    this.funds = (await this.$store.dispatch('regReporting/getFunds')).map( (fund: FundTableItem) => {
      return { ...fund, actions: [FundTableActions.GO_TO_FUND]}
    });
  }

  goToAddNewFund(): void {
    this.$router.push({name: 'add-new-fund'});
  }
}

