var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "funds-empty-table"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/fund--grey.svg")
    }
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v("You don't have any Fund")]), _c('sygni-rounded-button', {
    staticClass: "filled gn-primary",
    attrs: {
      "plus-icon": true
    },
    on: {
      "click": function click($event) {
        return _vm.goToAddNewFund();
      }
    }
  }, [_vm._v("Add new Fund")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }